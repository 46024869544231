<template>
  <v-theme-provider light id="demandedoc">
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="4"
      :key="kdoc"
    >
      <v-toolbar dense floating class="mt-n2 ml-n2" color="#73DDFF">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="solder_demande"
              v-if="
                annuler &&
                (dem_type == 2 ||
                  ((dem_type == 1 || dem_type == 3) &&
                    editedItem.tot_solde > 0)) &&
                editedItem.statut_id == '7'
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-file-cancel </v-icon>
              {{ dem_type == 1 || dem_type == 3 ? "Solder" : "Annuler" }}
            </v-btn>
          </template>
          <span
            >{{ dem_type == 1 || dem_type == 3 ? "Solder " : "Annuler " }} cette
            demande</span
          >
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="valider_demande"
              v-if="
                valider &&
                editedItem.statut_id == '6' &&
                detail_list.length > 0 &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon left> mdi-check-bold </v-icon>
              Valider
            </v-btn>
          </template>
          <span>Valider Demande</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="deleteDmd"
              v-if="
                editer &&
                editedItem.statut_id == '6' &&
                detail_list.length == 0 &&
                !edit
              "
              :disabled="editedItem.nbdocs > 0"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-delete </v-icon>
              Supprimer
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="traiter_demande"
              v-if="
                traiter &&
                dem_type == 2 &&
                editedItem.statut_id == '7' &&
                detail_list.length > 0
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-file-move </v-icon>
              Traiter
            </v-btn>
          </template>
          <span>Traiter cette demande d'offre </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="convertir_demande"
              v-if="
                convertir &&
                dem_type == 2 &&
                editedItem.statut_id == '71' &&
                detail_list.length > 0
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-file-move </v-icon>
              Convertir
            </v-btn>
          </template>
          <span
            >Transformer demande en
            {{
              dem_type == 1
                ? "Commande"
                : dem_type == 3
                ? "Bon de transfert"
                : "Proforma"
            }}</span
          >
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="modifier"
              v-if="editer && editedItem.statut_id == '6' && !edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon left> mdi-pencil </v-icon>
              Modifier
            </v-btn>
          </template>
          <span>Editer pour modification</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="cancel"
              v-if="editer && editedItem.statut_id == '6' && edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-file-cancel </v-icon>
              Annuler
            </v-btn>
          </template>
          <span>Annuler les Modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="save"
              v-if="editer && editedItem.statut_id == '6' && edit"
              v-bind="attrs"
              v-on="on"
              :disabled="save_disable"
              ><v-icon left> mdi-content-save-outline </v-icon>
              Enregistrer
            </v-btn>
          </template>
          <span>Enregistrer les modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="printDmd"
              v-if="editedItem.statut_id > '6'"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-printer </v-icon></v-btn
            >
          </template>
          <span>Imprimer</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01061') || $store.state.isadmin)
              "
              @click="OpenDocList"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-paperclip </v-icon>
            </v-btn>
          </template>
          <span>Documents Attachés</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="unlock"
              v-if="
                ($store.state.auth.includes('01068') || $store.state.isadmin) &&
                editedItem.qte_clt == 0 &&
                editedItem.qte_cmd == 0 &&
                !editedItem.locked &&
                editedItem.statut_id == '7' &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>Déverrouiller</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01060') || $store.state.isadmin)
              "
              @click.stop="drawer = !drawer"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-clipboard-text-clock-outline </v-icon>
            </v-btn>
          </template>
          <span>Historique Document</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="editedItem.id > 0 && editer"
              @click.stop="drawer2 = !drawer2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-notebook-edit-outline </v-icon>
            </v-btn>
          </template>
          <span>Suivi Demande</span>
        </v-tooltip>

        <v-progress-linear
          v-if="progress"
          indeterminate
          :size="30"
          :width="7"
          color="purple"
        ></v-progress-linear>
      </v-toolbar>

      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="3" md="3" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>
            <v-col cols="12" sd="6" md="6">
              <v-alert v-model="alert" :type="type_alert" dismissible>{{
                message
              }}</v-alert>
            </v-col>
            <!-- <v-col
            cols="12"
            sd="9"
            md="9"
            align-self="center"
            class="text-center"
          >
            <h1>{{ myname }}</h1>
          </v-col> -->
          </v-row>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="6" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="11" md="11">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="1"
                        md="1"
                        lg="1"
                        v-if="dem_type == 2 && edit"
                      >
                        <v-checkbox
                          class="mt-1"
                          v-model="new_tier"
                          on-icon="mdi-account-multiple-plus"
                          off-icon="mdi-account-multiple-plus-outline"
                          @change="new_tier_click"
                          color="red"
                          :readonly="!edit || detail_list.length > 0"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="edit && new_tier && dem_type == 2"
                      >
                        <v-text-field
                          ref="ref"
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.tier_name"
                          :label="'Nouveau Client'"
                          :rules="[(v) => !!v || 'Client obligatoire']"
                          :readonly="!edit || detail_list.length > 0"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="edit && !new_tier && dem_type == 2"
                      >
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="tier_id"
                          :text_fields="['code_client', 'nom']"
                          :variableadd="variableadd"
                          :item_text="'tier_name'"
                          @change="tierChange"
                          :label="'Client'"
                          :readonly="
                            !modify ||
                            (detail_list.length > 0 && editedItem.tier_id > 0)
                          "
                          :key="cs"
                        >
                        </cursorselect>
                      </v-col>

                      <!-- <v-col
                        cols="12"
                        sm="1"
                        md="1"
                        lg="1"
                        v-if="dem_type == 2"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="
                                modify &&
                                edit_tier &&
                                edit &&
                                detail_list.length == 0
                              "
                              depressed
                              @click.stop="newtier"
                              class="mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon> mdi-plus </v-icon>
                            </v-btn>
                          </template>
                          <span>Ajouter Client </span>
                        </v-tooltip>
                      </v-col> -->
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="dem_type == 2">
                  <v-col cols="12" sd="2" md="4" class="text-left" v-if="!edit">
                    Client
                  </v-col>

                  <v-col cols="12" sd="2" md="8" v-if="!edit">
                    <strong>
                      <h3>{{ editedItem.tier_name }}</h3></strong
                    >
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Code </v-col>
                  <v-col cols="12" sd="2" md="8">
                    <a @click="gotier" v-if="consult_tier">
                      {{ editedItem.tier ? editedItem.tier.code_client : "" }}
                    </a>
                    <div v-else>
                      {{ editedItem.tier ? editedItem.tier.code_client : "" }}
                    </div>
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Adresse</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.tier ? editedItem.tier.adresse : "" }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> R.C. N°</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.tier ? editedItem.tier.RC : "" }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4">N° Article</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.tier ? editedItem.tier.ART : "" }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4">Id Fiscal</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.tier ? editedItem.tier.NIF : "" }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4">N° Compte</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.tier ? editedItem.tier.cmpt : "" }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sd="2" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="12" md="12">
                    <h2>{{ formTitle }}</h2>
                    <br />
                    <strong> Date : {{ datefr(editedItem.date_da) }}</strong>
                    <br />
                  </v-col>
                  <v-col cols="12" sd="12" md="12" v-if="!edit">
                    Ref. : {{ editedItem.ref_da }}
                  </v-col>
                  <v-col
                    cols="12"
                    sd="12"
                    md="12"
                    v-if="!edit && dem_type == 1"
                  >
                    Destination : {{ editedItem.destination }}
                  </v-col>
                  <v-col
                    cols="12"
                    sd="12"
                    md="12"
                    v-if="!edit && dem_type == 3"
                  >
                    Depot destination : {{ editedItem.depot_name }}
                  </v-col>
                  <v-col
                    cols="12"
                    sd="12"
                    md="12"
                    lg="12"
                    v-if="!edit && dem_type == 3"
                  >
                    <p>
                      Effectué Par :
                      {{ editedItem.agent_name ? editedItem.agent_name : "" }}
                    </p>
                  </v-col>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="6" md="4">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.ref_da"
                          label="Reférence"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="5" v-if="dem_type == 1">
                        <v-text-field
                          dense
                          v-model="editedItem.destination"
                          label="Destination"
                          :rules="[(v) => !!v || 'Destination obligatoire']"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="5" v-if="dem_type == 3">
                        <v-autocomplete
                          ref="dt"
                          v-model="editedItem.depot_id"
                          item-text="label"
                          item-value="id"
                          :items="depots_to"
                          label="Depot de Destination"
                          :rules="[(v) => !!v || 'Depot obligatoire']"
                          dense
                          :disabled="save_disable"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sd="6" md="3" v-if="dem_type == 2">
                        <v-text-field
                          autocomplete="off"
                          type="number"
                          dense
                          class="inputPrice"
                          v-model.number="editedItem.delai"
                          :label="'Délai (Jours)'"
                          :readonly="!edit"
                          @input="addDays"
                          hide-spin-buttons
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="4"
                        md="3"
                        class="text-left"
                        v-if="dem_type == 2"
                      >
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          type="number"
                          v-model.number="editedItem.validite"
                          label="Validite (jours)"
                          :rules="[(v) => !!v || 'Validite obligatoire']"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="6"
                        md="5"
                        class="text-left"
                        v-if="dem_type == 2"
                      >
                        <v-select
                          dense
                          :items="modalites"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.modalite_id"
                          :rules="[
                            (v) => !!v || 'Modalité de Paiement obligatoire',
                          ]"
                          label="Modalité de Paiement"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="6"
                        md="4"
                        class="text-left"
                        v-if="dem_type == 2"
                      >
                        <v-select
                          dense
                          :items="modes_list"
                          item-text="mode"
                          item-value="id"
                          v-model="editedItem.modepaiement"
                          :rules="[
                            (v) => !!v || 'Mode de Paiement obligatoire',
                          ]"
                          label="Mode de Paiement"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="7" v-if="dem_type == 3">
                        <v-autocomplete
                          :items="agents"
                          v-model="editedItem.agent_id"
                          :item-value="'id'"
                          :item-text="
                            (item) => item.matricule + ' - ' + item.nom
                          "
                          :filter="customFilter"
                          dense
                          :rules="[
                            (v) => !!v || 'Responsable opération obligatoire',
                          ]"
                          :label="'Responsable'"
                          :readonly="!modify || !edit || ag_redonly"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <listitems
                :list="detail_list"
                :title="'Produits'"
                :headers="products_headers"
                :filename="'Produits'"
                :sheetname="'Produits'"
                @open="OpenProductForm"
                @btn_click="OpenProductForm"
                @btn_update_click="Dupliquer"
                :btn_update="
                  editer &&
                  dem_type == 2 &&
                  editedItem.statut_id == '6' &&
                  editedItem.id > 0 &&
                  detail_list.length > 0
                "
                :btn_update_tip="'Dupliquer'"
                :btn_update_icon="'mdi-content-duplicate'"
                :master="true"
                :showedit="false"
                :key="klist"
                :add="
                  editer && editedItem.statut_id == '6' && editedItem.id > 0
                "
                :Update="
                  editer && editedItem.statut_id == '6' && editedItem.id > 0
                "
                :del="
                  editer && editedItem.statut_id == '6' && editedItem.id > 0
                "
                :elevation="0"
                @delete="deleteitem"
                @file_deleted="file_deleted"
                @col_btn1_click="CommentOpen"
              >
              </listitems>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit">
            <v-col cols="12" sd="6" md="7">
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Délai

                  {{
                    editedItem.delai
                      ? editedItem.delai +
                        " jours (" +
                        datefr(editedItem.date_limite) +
                        ")"
                      : ""
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Etablit Par : {{ editedItem.contact }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template>
            <confirmdialog
              ref="confirm"
              :is_motif="is_motif"
              @motif="get_motif"
            />
          </template>

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            top
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <demandedetailform
            :item="product"
            :dem_type="dem_type"
            :items="detail_list"
            :readonly="editedItem.statut_id != '6'"
            :key="dkey"
            :showForm="!isDetailClosed"
            @close="closeDetailForm"
            :demande="editedItem"
            :units="units.filter((elm) => elm.uom_type == 'm')"
            :tier_products="tier_products"
            :products="products"
          >
          </demandedetailform>

          <filelist
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="12"
            :auth="'01070'"
          >
          </filelist>
          <demandecomment
            :item="product"
            :edit="editedItem.statut_id == 7 && traiter"
            :traiter="traiter"
            :key="kc"
            :showForm="!isCommentClose"
            @close="isCommentClose = true"
          >
          </demandecomment>
          <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            right
            width="600"
          >
            <v-card class="overflow-y-auto" flat>
              <v-card-title>
                <v-toolbar color="grey lighten-2" flat>
                  <v-toolbar-title> Historique du Document </v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(item, i) in operations"
                    :key="i"
                    fill-dot
                    small
                    :color="item.color"
                    :icon="item.icon"
                  >
                    <strong>{{ item.label }}</strong>
                    {{
                      " Par " +
                      item.user_name +
                      " le " +
                      datefr(item.created_at, true)
                    }}
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-navigation-drawer>
          <v-navigation-drawer v-model="drawer2" absolute temporary width="600">
            <comments
              :title="'Suivi Demande'"
              :comments="comments"
              :document_type="12"
              :doc_id="parseInt(editedItem.id)"
            ></comments>
          </v-navigation-drawer>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
import DELETE_DEMANDE_DETAIL from "../graphql/Demande/DELETE_DEMANDE_DETAIL.gql";
import DELETE_DEMANDE from "../graphql/Demande/DELETE_DEMANDE.gql";
import CREATE_DEMANDE from "../graphql/Demande/CREATE_DEMANDE.gql";
import UPDATE_DEMANDE from "../graphql/Demande/UPDATE_DEMANDE.gql";
import UPDATE_DEMANDE_DETAIL from "../graphql/Demande/UPDATE_DEMANDE_DETAIL.gql";
import ALLPRODUCTS from "../graphql/Commande/ALLPRODUCTS.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import CREATE_PFM from "../graphql/Proforma/CREATE_PFM.gql";
import CREATE_PFM_DETAIL from "../graphql/Demande/CREATE_PFM_DETAIL.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import ALLARTICLES_DEPOT from "../graphql/Depot/ALLARTICLES_DEPOT.gql";
import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";

import {
  myLogo,
  myName,
  myIF,
  myRC,
  myART,
  grt,
  spec_tech,
  image_prod,
  bag,
  sell_unit,
} from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    demandedetailform: () => import("./DemandeDetailForm.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    comments: () => import("./Comment.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
    demandecomment: () => import("../components/DemandeComment.vue"),
  },
  props: {
    dem_type: Number,
    demande: Object,
    editer: Boolean,
    valider: Boolean,
    annuler: Boolean,
    traiter: Boolean,
    technicien: Boolean,
    convertir: Boolean,
    modalites: Array,
    modes: Array,
    units: Array,
    agents: Array,
    edition: { type: Boolean, default: false },
  },
  data() {
    return {
      save_disable: false,
      message: "",
      alert: false,
      type_alert: "error",
      datepicker2: false,
      drawer: null,
      drawer2: null,
      modify: false,
      progress: false,
      duedate: null,
      valid: true,
      is_motif: false,
      edit_tier: false,
      consult_tier: false,
      isCommentClose: true,
      cs: 800,
      kc: 250,
      products: [],
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      act_save: true,
      isDetailClosed: true,
      isProdClosed: true,
      isDocListClose: true,
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_text: "",
      snackbar_color: "primary",
      tier_products: [],
      mc: 200,
      ag_redonly: false,
      dkey: 100,
      pkey: 300,
      fdocl: 1000,
      detail: {},
      demandes_list: [],
      isOrderClosed: true,
      order: {},
      changed: false,
      korder: 0,
      product: {},
      tier_id: null,
      new_tier: false,
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
          index: 0,
        },
        {
          text: "Code",
          value: "code",
          slot: "href",
          selected: true,
          align: "left",
          index: 1,
        },
        {
          text: "Produit",
          value: "label",
          column: "produit_id",
          description: "description",
          selected: true,
          align: "left",
          index: 2,
        },

        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          width: "120px",
          selected: true,
          index: 3,
        },
        {
          text: "Unite",
          value: "unit_name",
          selected: true,
          index: 4,
        },
        {
          text: "Consultation",
          value: "qte_clt",
          slot: "cur",
          align: "end",
          width: "120px",
          hiden: true,
          selected: true,
          index: 5,
        },
        {
          text: "Commande",
          value: "qte_cmd",
          slot: "cur",
          align: "end",
          width: "120px",
          hiden: true,
          selected: true,
          index: 6,
        },

        {
          text: "Spec. Tech",
          value: "spec_need",
          slot: "checkbox",
          selected: true,
          hiden: true,
          index: 7,
        },
        {
          text: "Aperçu",
          value: "preview_file",
          align: "left",
          slot: "upload/download",
          upload: false,
          download: true,
          preview: true,
          delete: false,
          sh_upload: false,
          sh_delete: false,
          var_name: "demandedetail",
          update_query: UPDATE_DEMANDE_DETAIL,
          sortable: false,
          selected: true,
          hiden: false,
          index: 8,
        },
        {
          text: "Specification du produit",
          value: "spec_tech",
          align: "left",
          slot: "upload/download",
          upload: false,
          sh_upload: false,
          sh_delete: false,
          download: true,
          preview: true,
          delete: false,

          column1: "statut_id",
          valeur1: "1",

          sortable: false,
          selected: true,
          var_name: "demandedetail",
          update_query: UPDATE_DEMANDE_DETAIL,
          box_filter: "application/pdf",
          hiden: true,
          index: 9,
        },
        {
          text: "Fichier Source",
          value: "url",
          align: "left",
          slot: "upload/download",
          upload: false,
          download: true,
          preview: true,
          delete: false,
          sh_upload: false,
          sh_delete: false,
          var_name: "demandedetail",
          update_query: UPDATE_DEMANDE_DETAIL,
          sortable: false,
          selected: false,
          hiden: false,
          index: 10,
        },
        {
          text: "Comment",
          value1: "comment",
          slot: "col_btn1",
          icon1: "mdi-text-box-check",
          icon2: "mdi-file-document-edit",
          selected: true,
          hiden: false,
          index: 11,
        },
        {
          text: "Update",
          slot: "actions",
          selected: true,
          hiden: true,
          index: 12,
        },
      ],
      edit: false,
      editedItem: {},
      demande_list: [],
      kdoc: 700,
      klist: 500,
      cmpt: 0,
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        statut_id: "6",
        details: [],
        docs: [],
        date_da: null,
        nbdocs: 0,
      },
    };
  },
  async created() {
    this.products_headers[5].hiden = this.dem_type == 2 || this.dem_type == 3;
    this.products_headers[6].hiden = this.dem_type == 2 || this.dem_type == 3;

    //this.products_headers[8].text = this.image_prod;
    this.products_headers[8].hiden =
      this.dem_type == 1 || this.dem_type == 3 || !bag;
    if (this.spec_tech) {
      this.products_headers[7].text = this.spec_tech;
      this.products_headers[9].text = "Fichier " + this.spec_tech;
    }

    this.products_headers[7].hiden =
      this.dem_type == 1 || this.dem_type == 3 || !this.spec_tech;
    this.products_headers[9].hiden =
      this.dem_type == 1 || this.dem_type == 3 || !bag;
    this.products_headers[10].hiden =
      this.dem_type == 1 || this.dem_type == 3 || !bag;
    this.products_headers[11].hiden = this.dem_type == 1 || this.dem_type == 3;

    if (this.demande.id) {
      this.edit = this.edition;
      if (this.demande.id > 0) {
        this.editedItem = Object.assign({}, this.demande);

        this.cs++;
        this.tier_id = this.editedItem.tier_id;
        if (!this.editedItem.tier_id && !this.editedItem.cons_id) {
          this.new_tier = true;
          this.new_tier_click();
        }

        if (
          this.editedItem.statut_id == "3" ||
          this.editedItem.statut_id == "9"
        ) {
          this.message =
            "Motif : " + (this.editedItem.motif ? this.editedItem.motif : "");
          this.alert = true;
        }

        if (this.editedItem.statut_id == "8" && this.editedItem.dem_type == 2) {
          this.message =
            "Convertie en proforma N° : " +
            (this.editedItem.nopfm ? this.editedItem.nopfm : "");
          this.type_alert = "success";
          this.alert = true;
        }
        this.addDays();
        await this.get_products_tier();
        if (this.editedItem.dem_type == 3) {
          let v = {
            where: {
              AND: [
                {
                  column: "STOCK_CMPT",
                  operator: "GT",
                  value: 0,
                },
                {
                  column: "LINKCOUNT",
                  value: 0,
                },
                {
                  column: "DEPOT_ID",
                  operator: "IN",
                  value: this.$store.state.depots.map((elm) => elm.id),
                },
              ],
            },
          };
          let r = await this.requette(ALLARTICLES_DEPOT, v);
          if (r) {
            this.products = r.allarticles_depot;
          }
        }
      } else {
        this.defaultItem.date_da = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.$store.state.me.agent_id && !this.$store.state.isadmin) {
          if (this.demande.id < 0)
            this.editedItem.agent_id = this.$store.state.me.agent_id;
          this.ag_redonly = true;
        }
      }
    }

    this.products_headers[12].hiden = this.editedItem.statut_id != "6";

    this.edit_tier =
      (this.dem_type == 2 && this.$store.state.auth.includes("02029")) ||
      (this.dem_type == 1 && this.$store.state.auth.includes("02030")) ||
      this.$store.state.isadmin;
    this.consult_tier =
      (this.dem_type == 2 && this.$store.state.auth.includes("0129")) ||
      (this.dem_type == 1 && this.$store.state.auth.includes("0130")) ||
      this.$store.state.isadmin;
    this.modify = this.editer && this.editedItem.statut_id == "6";
  },
  methods: {
    CommentOpen(item) {
      this.product = item;
      this.isCommentClose = false;
      this.kc++;
    },

    async file_uploaded() {
      this.$store.dispatch("Changed", true);
    },
    async file_deleted() {
      this.$store.dispatch("Changed", true);
    },
    new_tier_click() {
      if (this.new_tier) {
        this.editedItem.tier_id = null;
        this.editedItem.code_client = null;
        this.editedItem.adresse = null;
        this.editedItem.currency_id = null;
        this.editedItem.tier = {};
      } else {
        this.editedItem.tier_name = null;
      }
    },
    async unlock() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller cette demande?"
        )
      ) {
        this.editedItem.statut_id = 6;

        let v = {
          demande: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
            write_uid: this.$store.state.me.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_DEMANDE, v, true);
        this.$store.dispatch("Editing", false);
      }
    },
    async get_products_tier() {
      if (this.demande.tier_id > 0) {
        let v = {
          where: { column: "CLIENT_ID", value: this.demande.tier_id },
          Kind: 3,
          Type: this.$store.state.products_types.map((elm) => elm.id),
          statut_id: [2],
        };
        let r = await this.requette(ALLPRODUCTS, v);
        if (r) {
          this.tier_products = r.allproducts;
        }
      }
    },
    gotier() {
      if (this.dem_type == 2)
        this.$router.push({
          name: "clients",
          params: {
            dmd: this.demande,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.tier.code_client,
            tier_name: this.editedItem.tier_name,
            name: "offre",
          },
        });
    },
    newtier() {
      this.$router.push({
        name: "clients",
        params: {
          dmd: this.demande,
          tier: -1,
          tier_name: this.editedItem.tier_name,
          name: "offre",
        },
      });
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l = ["code_client", "nom"];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    tierChange(item, l) {
      let i;
      let list = [];
      if (l) list = l;
      this.tier_id = item;
      if (this.tier_id) {
        i = list.findIndex((elm) => elm.id == this.tier_id);

        this.editedItem.code_client = null;
        this.editedItem.adresse = null;
        this.editedItem.currency_id = null;
        if (this.editedItem.tier) {
          this.editedItem.tier.RC = null;
          this.editedItem.tier.ART = null;
          this.editedItem.tier.NIF = null;
          this.editedItem.tier.statut_id = null;
        }

        if (i >= 0) {
          this.editedItem.tier_id = list[i].id;

          this.editedItem.tier = {};
          this.editedItem.tier.adresse = list[i].adresse;
          this.editedItem.tier.code_client = list[i].code_client;
          this.editedItem.tier.RC = list[i].RC;
          this.editedItem.tier.ART = list[i].ART;
          this.editedItem.tier.NIF = list[i].NIF;
          this.editedItem.tier.statut_id = list[i].statut_id;
          this.editedItem.currency_id = list[i].currency_id;
          if (this.editedItem.id < 0) {
            this.editedItem.modepaiement = list[i].modepaiement;
            this.editedItem.modalite_id = list[i].modalite_id;
          }
          this.editedItem.devise = list[i].devise;
        }
      }
      this.cmpt++;
    },

    async modifier() {
      if (await this.verif_modif()) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
    },
    async cancel() {
      let ok = true;
      if (
        (this.cmpt > 0 && this.editedItem.id < 0) ||
        (this.cmpt > 1 && this.editedItem.id > 0)
      ) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.editedItem.id < 0) {
          this.$emit("cancel", this.editedItem);
          this.editedItem.id = null;
        }
        this.edit = false;
        this.$emit("edition", false);
        this.kdoc++;
        this.$store.dispatch("Changed", true);
      }
    },
    async deleteitem(item) {
      if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimmer cette ligne?",
            { color: "orange darken-3" }
          )
        ) {
          let r = await this.maj(DELETE_DEMANDE_DETAIL, { id: item.id });
          if (r) {
            this.detail_list.splice(item.index, 1);
            this.$store.dispatch("Changed", true);
            this.$store.dispatch("Editing", false);
            this.update_modif();
            this.kdoc++;
          }
        } else this.update_modif();
      }
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          demande: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_DEMANDE, v);
      }
    },
    nbjour() {
      this.datepicker2 = false;
      var date2 = new Date(this.duedate);
      var date1 = new Date(this.editedItem.date_da);
      var diff = date2.getTime() - date1.getTime();
      this.editedItem.delai = Math.floor(diff / (24 * 3600 * 1000));
      this.cmpt++;
    },
    addDays() {
      if (this.editedItem.delai) {
        let d = parseInt(this.editedItem.delai);
        var result = new Date(this.editedItem.date_da);
        result.setDate(result.getDate() + d);
        this.duedate = result.toISOString().substr(0, 10);
      } else this.duedate = null;
      this.cmpt++;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async traiter_demande() {
      let ok = true;

      if (ok)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir traiter cette demande?",
            { color: "orange darken-3" }
          )
        ) {
          let status;
          status = 71;

          let v = {
            demande: {
              id: this.editedItem.id,
              statut_id: status,
              write_uid: this.$store.state.me.id,
              modif_by: null,
              modif_date: null,
            },
          };
          let r = await this.maj(UPDATE_DEMANDE, v, true);
          if (r) {
            this.editedItem.statut_id = status;
          }
        }
    },
    async convertir_demande() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir convertir cette demande?"
        )
      ) {
        let v;
        // créer proforma
        v = {
          input: {
            pfm_type: this.dem_type == 1 ? 2 : 1,
            tier_id: this.demande.tier_id,
            date_pfm: this.$store.state.today,
            statut_id: 1,
            date_opr: this.$store.state.today,
            dem_id: this.demande.id,
            comment: this.demande.comment,
            currency_id: this.demande.currency_id,
            validite: parseInt(this.demande.validite),
            modalite_id: this.demande.modalite_id,
            modepaiement: this.demande.modepaiement,
            delai: parseInt(this.demande.delai),
            tier_name: this.demande.tier_id ? null : this.demande.tier_name,

            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let r = await this.maj(CREATE_PFM, v);
        if (r) {
          let pfm = r.createPfm;
          // créer detail proforma
          let l = [];
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            l.push({
              qte: parseFloat(element.qte),
              pfm_id: pfm.id,
              delai: element.delai,
              produit_id: element.produit_id,
              article_id: element.article_id,
              label: element.label,
              comment: element.comment,
              url: element.url,
              spec_tech: element.spec_tech,
              preview_file: element.preview_file,
              fk_unit: element.fk_unit,
              tva_tx: parseFloat(element.tva_tx),
              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            });
          }
          let new_comments = [];
          if (this.comments.length > 0) {
            this.comments.forEach((element) => {
              new_comments.push({
                document_id: parseInt(pfm.id),
                document_type: 1,
                comment_type: parseInt(element.comment_type),
                comment: element.comment,
                created_at: element.created_at,
                create_uid: element.create_uid,
              });
            });
            new_comments.push({
              document_id: parseInt(pfm.id),
              document_type: 1,
              comment_type: 4,
              comment:
                "------Fin Notes Demande N° " +
                this.demande.no_da +
                "------------",
              created_at: new Date().toISOString().slice(0, 19),
              create_uid: this.$store.state.me.id,
            });
          }
          v = {
            input: l,
            pfm_id: pfm.id,
            comments: new_comments.length > 0 ? new_comments : null,
          };
          let r1 = await this.maj(CREATE_PFM_DETAIL, v);
          if (r1) {
            this.editedItem.statut_id = 8;
            v = {
              demande: {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
                modif_by: null,
                modif_date: null,
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_DEMANDE, v);
            //actualiser les alertes
          }
          this.$store.dispatch("Changed", true);
          this.$store.dispatch("Editing", false);

          this.$router.push({
            name: "proformas_c",
            params: { pfm: pfm },
          });
        }
      }
    },
    async verif_modif() {
      this.alert = false;

      let ok = false;
      let v = {
        table: "demandes",
        statut_id: "6",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);

        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2) {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      return ok;
    },
    Dupliquer(item) {
      this.product = item;
      this.product.id = -1;
      this.product.qte = null;
      this.product.url = null;
      this.product.spec_tech = null;
      this.product.preview_file = null;
      this.product.dem_id = this.editedItem.id;
      this.product.tier_id = this.editedItem.tier_id;
      this.isDetailClosed = false;
      this.dkey++;
    },
    async OpenProductForm(item) {
      if (item.col == "code") {
        if (this.dem_type == 2)
          await this.$router
            .push({
              name: "product_tosell",
              params: { dmd: this.demande, item: item, name: "offre" },
            })
            .catch(() => {});
        if (this.dem_type == 1)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { dmd: this.demande, item: item, name: "da" },
            })
            .catch(() => {});
        if (this.dem_type == 3)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { dmd: this.demande, item: item, name: "dtransf" },
            })
            .catch(() => {});
      } else if (
        item.produit_id ||
        item.id < 0 ||
        this.editedItem.statut_id == "6"
      ) {
        if (await this.verif_modif()) {
          this.product = item;
          this.product.fk_unit = sell_unit;
          this.product.dem_id = this.editedItem.id;
          this.product.tier_id = this.editedItem.tier_id;
          this.isDetailClosed = false;
          this.dkey++;
        }
      } else {
        await this.$router
          .push({
            name: "product_tosell",
            params: { demande: this.demande, item: item, name: "offre" },
          })
          .catch(() => {});
      }
    },

    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    async maj(query, v, refresh) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    get_motif(m) {
      this.editedItem.motif = m;
    },
    async solder_demande() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (this.dem_type == 1 || this.dem_type == 3 ? "solder" : "annuler") +
            " cette demande?",
          { color: "orange darken-3" }
        )
      ) {
        this.editedItem.statut_id =
          this.dem_type == 1 || this.dem_type == 3 ? 9 : 3;
        let v = {
          demande: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
            write_uid: this.$store.state.me.id,
            motif: this.editedItem.motif,
            modif_by: null,
            modif_date: null,
          },
        };
        this.maj(UPDATE_DEMANDE, v, true);
      }
      this.is_motif = false;
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async valider_demande() {
      if (await this.verif_modif()) {
        let ok = true;
        if (this.dem_type == 2 && bag) {
          //verifier aperçu et fichier source
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            if (!element.preview_file) {
              ok = false;
              this.alert = true;
              this.message = "Aperçu manquant pour le produit " + element.label;
              this.type_alert = "error";
              break;
            }
            if (!element.url) {
              ok = false;
              this.alert = true;
              this.message =
                "Fichier source manquant pour le produit " + element.label;
              this.type_alert = "error";
              break;
            }
          }
        }
        if (ok) {
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir valider cette demande?"
            )
          ) {
            let status_id;
            status_id = 7;

            let v = {
              demande: {
                id: this.editedItem.id,
                statut_id: status_id,
                date_da: this.editedItem.date_da,
                write_uid: this.$store.state.me.id,
                comment: this.editedItem.comment,
                ref_da: this.editedItem.ref_da,
                destination: this.editedItem.destination,

                modif_by: null,
                modif_date: null,

                delai: parseInt(this.editedItem.delai),
              },
            };
            let r = await this.maj(UPDATE_DEMANDE, v, true);
            if (r) {
              this.editedItem.statut_id = status_id;
              this.$store.dispatch("Editing", false);
            }
          }
        }
      }
    },
    closeDetailForm() {
      this.isDetailClosed = true;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async deleteDmd() {
      if (this.editedItem.id < 0) {
        this.editedItem.id = null;
        this.$emit("cancel", this.editedItem);
      } else if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cette demande?"
          )
        ) {
          let v = {
            id: this.editedItem.id,
          };
          let r = await this.requette(DELETE_DEMANDE, v);
          if (r) {
            this.editedItem = { id: null };
            this.$store.dispatch("Changed", true);
          }
        } else this.update_modif();
      }
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },

    printDmd() {
      let doctype = [];
      doctype.push({
        lib: {
          content:
            this.dem_type == 1
              ? "Demande d'Achat"
              : this.dem_type == 3
              ? "Demande de transfert"
              : "Demande d'Offre",
          styles: { fontStyle: "bold", fontSize: 20, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: this.editedItem.no_da,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.editedItem.date_da),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Réference:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.ref_da ? this.editedItem.ref_da : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });

      if (this.editedItem.delai)
        doctype.push({
          lib: {
            content: "Délai de Livraison:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.delai
              ? this.editedItem.delai + " Jours"
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });

      doctype.push({
        lib: {
          content: "Contact:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.contact ? this.editedItem.contact : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      let tier = [];
      if (this.dem_type == 2) {
        tier.push({
          lib: {
            content: "Client :",
            styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content: this.editedItem.tier_name ? this.editedItem.tier_name : "",
            styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content: this.editedItem.tier
              ? this.editedItem.tier.adresse
                ? this.editedItem.tier.adresse
                : ""
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });

        tier.push({
          lib: {
            content: "Tél :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.tier
              ? this.editedItem.tier.tel
                ? this.editedItem.tier.tel
                : ""
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "Email :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.tier
              ? this.editedItem.tier.email
                ? this.editedItem.tier.email
                : ""
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      }
      let foot = [];

      let detailheaders = [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Ref.",
          value: "code",
          selected: true,
          align: "left",
          width: 20,
        },
        {
          text: "Désignation",
          value: "label",
          selected: true,
          align: "left",
          width: "auto",
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "qte",
          width: 30,
          align: "end",
          selected: true,
        },
        {
          text: "Unité",
          value: "unit_name",
          selected: true,
        },
        {
          text: "PU HT",
          value: "pu",
          width: 25,
          align: "end",
          selected: true,
        },
      ];
      let lettre = [];

      doc_template(
        this.$store,
        detailheaders,
        this.detail_list,
        doctype,
        tier,
        foot,
        lettre,
        this.editedItem.notes,
        this.formTitle
      );
    },
    sumtaxTotal(key) {
      // sum data in give key (property)
      return this.taxTotal
        ? this.taxTotal.reduce((a, b) => a + (b[key] || 0), 0)
        : 0;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;
        this.save_disable = true;
        if (ok) {
          if (this.editedItem.id > 0) {
            let v = {
              demande: {
                id: this.editedItem.id,
                date_da: this.editedItem.date_da,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                ref_da: this.editedItem.ref_da,
                tier_id: this.editedItem.tier_id,
                destination: this.editedItem.destination,
                depot_id: this.editedItem.depot_id,
                client_name: this.editedItem.tier_id
                  ? null
                  : this.editedItem.tier_name,

                delai: parseInt(this.editedItem.delai),
                validite: parseInt(this.editedItem.validite),
                modalite_id: this.editedItem.modalite_id,
                modepaiement: this.editedItem.modepaiement,
                agent_id: this.editedItem.agent_id,
                modif_by: null,
                modif_date: null,

                write_uid: this.$store.state.me.id,
              },
            };
            this.maj(UPDATE_DEMANDE, v, true);
          } else {
            let v = {
              demande: {
                dem_type: this.dem_type,
                date_da: this.$store.state.today,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                ref_da: this.editedItem.ref_da,
                tier_id: this.editedItem.tier_id,
                destination: this.editedItem.destination,
                depot_id: this.editedItem.depot_id,
                client_name: this.editedItem.tier_id
                  ? null
                  : this.editedItem.tier_name,

                delai: parseInt(this.editedItem.delai),
                validite: parseInt(this.editedItem.validite),
                modalite_id: this.editedItem.modalite_id,
                modepaiement: this.editedItem.modepaiement,
                agent_id: this.editedItem.agent_id,
                modif_by: null,
                modif_date: null,

                create_uid: parseInt(this.$store.state.me.id),
                write_uid: parseInt(this.$store.state.me.id),
              },
            };

            let r = await this.maj(CREATE_DEMANDE, v);
            if (r) {
              this.editedItem.id = r.createDemande.id;
              this.$store.dispatch("Changed", true);
              this.$emit("change", this.editedItem);
            }
          }
          this.edit = false;
          this.save_disable = false;
          this.$emit("edition", false);
          this.kdoc++;
        }
      }
    },
  },
  computed: {
    depots_to() {
      return this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 2
      );
    },
    image_prod() {
      return image_prod;
    },
    spec_tech() {
      return spec_tech;
    },
    modes_list() {
      return this.modes.filter((elm) => elm.type == 0);
    },
    variableadd() {
      let w = {
        TypeScope: 2,
        statut_id: [2, 3],
      };
      return w;
    },
    update_detail() {
      return UPDATE_DEMANDE_DETAIL;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },

    formTitle() {
      let d =
        this.dem_type == 1
          ? "Demande d'Achat"
          : this.dem_type == 3
          ? "Préparation de transfert"
          : "Demande d'Offre";
      return this.editedItem.id < 0 || !this.editedItem.no_da
        ? d
        : d + " N° " + this.editedItem.no_da;
    },
    garantie() {
      return grt ? true : false;
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },

    Qdelete() {
      return DELETE_DEMANDE_DETAIL;
    },

    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },

    operations: function () {
      return this.editedItem.operations ? this.editedItem.operations : [];
    },
    comments: function () {
      return this.editedItem.comments ? this.editedItem.comments : [];
    },
    detail_list: function () {
      return this.editedItem.details ? this.editedItem.details : [];
    },

    subTotal: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return accumulator + item.pu * item.qte;
          }, 0)
        : 0;
      return total;
    },

    taxTotal: function () {
      let taxs = [];
      if (
        this.detail_list &&
        (this.editedItem.exe_tva != "1" || this.exe_tva) &&
        this.editedItem.currency_id == 114
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0)
              taxs[i].tva =
                taxs[i].tva + (element.pu * element.qte * element.tva_tx) / 100;
            else
              taxs.push({
                taux: element.tva_tx,
                tva: (element.pu * element.qte * element.tva_tx) / 100,
              });
          }
        });
      }
      return taxs;
    },

    ttc: function () {
      let taxs = 0;
      this.taxTotal.forEach((element) => {
        taxs = taxs + element.tva;
      });

      var total = this.subTotal + taxs + this.editedItem.mont_transport;
      return total;
    },
    mont_exe_taxs: function () {
      let taxs = 0;
      if (
        this.detail_list &&
        this.exe_tva &&
        this.editedItem.currency_id == 114
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            taxs = taxs + (element.pu * element.qte * element.tva_tx) / 100;
          }
        });
      }
      return taxs;
    },
    grandTotal: function () {
      var total = this.ttc - this.mont_exe_taxs;
      return total;
    },
  },
};
</script>
<style scoped>
.scroll {
  width: auto;

  overflow-x: hidden;
  overflow-y: auto;
}
</style>
